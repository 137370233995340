<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('studies.studies') }}</span>
		</div>

		<v-divider class="my-2" />

		<Degrees :studies="studies" :student-emails="studentEmails" />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('studies.studentStatus'),
		}
	},
	data() {
		return {
			loading: false,
		}
	},
	components: {
		Degrees: () => import('@/components/studies/Degrees.vue'),
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
			studies: 'studies/studies',
			studentEmails: 'studies/studentEmails',
		}),
	},
	created() {
		this.loading = true
		this.sentStudentEmail = this.$route.query.studentEmail ? this.$route.query.studentEmail : ''
		if (this.$route.query.token) {
			this.verifyStudent(this.$route.query.token).then(() => {
				this.fetchStudies().then(() => {
					this.loading = false
				})
				this.fetchStudentEmails({ userID: this.currentUser.id })
			})
		} else {
			this.fetchStudies().then(() => {
				this.loading = false
			})
			this.fetchStudentEmails({ userID: this.currentUser.id })
		}
	},
	methods: {
		...mapActions('studies', ['fetchStudies', 'fetchStudentEmails', 'verifyStudent']),
	},
}
</script>
